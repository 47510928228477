import { render, staticRenderFns } from "./MediaFilters.vue?vue&type=template&id=b08318d2&scoped=true&"
import script from "./MediaFilters.vue?vue&type=script&lang=ts&"
export * from "./MediaFilters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b08318d2",
  null
  
)

export default component.exports