import { ActionTree } from 'vuex';
import { mediaAdapter } from '@api';
import { MediaState } from '.';
import { PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '@/store/modules/paginator';

const actions: ActionTree<MediaState, RootState> = {
    async upload({ commit, state, dispatch, getters }) {
        const file: Blob = getters['uploader/stageFile'];
        if (file) {
            await mediaAdapter.upload({ file, name: state.uploader?.file?.name });
            await dispatch(`pg/${PAGINATION_ACTIONS.load}`);
        }
    },
    async delete({ commit, state }, ids: number[]) {
        try {
            await mediaAdapter.delete(ids);
            commit('updateSelected', []);
        } catch (error) {
            throw error;
        }
    },
    async loadFilters({ commit }) {
        commit('setFilters', await mediaAdapter.getFilters());
    },
};
export default actions;
