import { MutationTree } from 'vuex';
import { MediaState } from './index';

const mutations: MutationTree<MediaState> = {
    updateSelected(state, payload) {
        state.selected = payload;
    },
    setFilters(state, payload) {
        state.filters = payload;
    },
};
export default mutations;
