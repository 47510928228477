

































import { Vue, Component } from 'vue-property-decorator';
import { MediaCard } from './components';
import module from './store';
import { BackgroundPreview, MedialListFilterParams } from './models';
import { namespace } from 'vuex-class';
import { PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '../../store/modules/paginator';
import CropperModal from '@/shared/components/Modal/CropperModal.vue';
import DialogModal, { DialogModalProps } from '@/shared/components/Modal/DialogModal.vue';
import { debounce } from '@/shared/utils';
import { StateChanger } from 'vue-infinite-loading';
import MediaFilters from './components/MediaFilters.vue';
const ns = namespace('media');
const pg = namespace('media/pg');

@Component({
    components: { MediaCard, MediaFilters },
    created() {
        this.$registerVuexModule('media', module);
    },
})
export default class Media extends Vue {
    @ns.State('selected') selected!: number[];
    @ns.State('filters') filters!: MediaFilters;
    @ns.Mutation('updateSelected') updateSelected!: (selected: number[]) => void;
    @ns.Action('delete') delete!: (payload: number[]) => Promise<void>;
    @ns.Action('loadFilters') loadFilters!: () => Promise<void>;
    @pg.Getter('pageItems') items!: BackgroundPreview[];
    @pg.Getter('total') totalItems!: number;
    @pg.Getter('dataId') dataId!: number;
    @pg.State('params') params!: MedialListFilterParams;
    @pg.Action(PAGINATION_ACTIONS.load) getPage!: () => Promise<void>;
    @pg.Mutation(PAGINATION_MUTATIONS.setParams) setParams!: (params: Partial<MedialListFilterParams>) => void;

    mounted() {
        this.loadFilters();
    }

    loadPage = debounce(async ({ event, reload = false }: { event?: StateChanger; reload: boolean }) => {
        await this.$store.dispatch(`media/pg/${PAGINATION_ACTIONS.load}`, { reload: reload, scrollEvent: event });
    }, 500);

    filterChanged(changes: Partial<MedialListFilterParams>) {
        this.setParams(changes);
        this.loadPage({ reload: true });
        this.updateSelected([]);
    }
    get filtersParams() {
        const { query, sort, ...filtersParams } = this.params;
        return filtersParams;
    }

    upload() {
        this.$modal.show(
            CropperModal,
            { uploadAction: 'media/upload', namespace: 'media/uploader' },
            {},
            {
                saved: () => {
                    this.loadPage({ reload: true });
                    this.loadFilters();
                },
            }
        );
    }
    deleteImages() {
        const selectedImages = this.items.filter(i => this.selected.includes(i.id));
        const systemImages = selectedImages.filter(i => !!i.isSystem);
        const notSystem = selectedImages.filter(i => !i.isSystem);
        // eslint-disable-next-line prettier/prettier
        const systemMessage = 'The following shared images cannot be deleted: ' + systemImages.map(i => i.name).join(', ') + '. ';
        const question = this.selected.length == 1 ? 'Delete the selected asset' : `Delete ${this.selected.length} selected assets?`;
        this.$modal.show(
            DialogModal,
            {
                title: 'Delete',
                question: !!systemImages.length
                    ? systemMessage + (!!notSystem.length ? `Delete other images(${selectedImages.length - systemImages.length})?` : '')
                    : question,
                action: async () => {
                    const dimesions = notSystem.map(i => `${i.width} x ${i.height}`);
                    await this.delete([...notSystem.map(i => i.id)]);
                    this.filterChanged({ dimensions: this.params.dimensions.filter(d => !dimesions.includes(d)) });
                    this.loadFilters();
                    return;
                },
                rejectText: !notSystem.length ? 'OK' : 'NO',
                showApply: !!notSystem.length,
            } as DialogModalProps,
            {}
        );
    }
}
