































import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { MediaListFilters, MedialListFilterParams } from '../models';

@Component({})
export default class MediaFilters extends Vue {
    @Prop({ default: { types: [], dimensions: [] }, type: Object }) filters!: MediaListFilters;
    @Model('change', { default: () => ({ types: [], dimensions: [] }), type: Object }) value!: Pick<MedialListFilterParams, 'dimensions'>;

    get dimensions() {
        return this.value.dimensions;
    }
    set dimensions(dimensions) {
        this.$emit('change', { ...this.value, dimensions });
    }
}
