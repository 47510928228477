import { ListPageState } from '@/shared/models';
import { Module } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { MedialListFilterParams, BackgroundPreview, MediaListFilters } from '../models';
import createUploaderModule, { UploadCropState } from '@/store/modules/uploadCrop';
import createPaginationModule, { PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { mediaAdapter as MediaApiAdapters } from '@api';

export interface MediaState {
    uploader?: UploadCropState;
    pg?: ListPageState<MedialListFilterParams, BackgroundPreview>;
    selected: number[];
    filters: MediaListFilters;
}

function getInitialState(): MediaState {
    return {
        selected: [],
        filters: {
            dimensions: [],
            tags: [],
            sort: {},
        },
    };
}

const module: Module<MediaState, RootState> = {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions: {
        ...actions,
        reset({ state, commit }) {
            Object.assign(state, getInitialState());
            commit(`pg/${PAGINATION_MUTATIONS.reset}`);
        },
    },
    mutations,
    modules: {
        pg: createPaginationModule<MedialListFilterParams, BackgroundPreview>({
            api: MediaApiAdapters,
            initialState: () => ({
                page: {
                    size: 18,
                    rows: [],
                    index: 0,
                    totalItems: 0,
                },
                params: {
                    dimensions: [],
                    tags: [],
                    query: '',
                    sort: 0,
                    sortAsc: true,
                },
                dataId: 1,
            }),
        }),
        uploader: createUploaderModule(),
    },
};
export default module;
